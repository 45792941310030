import React from "react";
import ContentWrapper from "~/components/content-wrapper";
import H1 from "~/components/Typography/H1";
import LessonLayout from "~/layouts/lesson";

const Page = () => {
  return (
    <LessonLayout
      current_step={14}
      lesson={"Lesson 4"}
      color={"ms"}
      total_count={15}
      module_title={undefined}
    >
      <ContentWrapper>
        <H1 underline>Step 14</H1>

        <p>This lesson focused on thinking about risks and benefits when making decisions about health issues.</p>
        <p>Pull up the copies of the rubric you have been working on. What would you add to a rubric about risks and benefits to help you make decisions about health?</p>
        <p>Talk with your teammates about what statements to add to your rubric. Think back to what you did in this lesson to help you. For example, you started the lesson by looking at a commercial for a prescription medicine. You saw that the commercial included both risks and benefits. How would you write that as a statement for your rubric?</p>
        <p>After you write your statements for your rubric, your teacher will lead a class discussion so you can share your ideas with your classmates.</p>
      </ContentWrapper>
    </LessonLayout>
  )
}

export default Page;

